import React, { useRef } from "react";
import gameArt from "../Images/Alan.png";
import useOnScreen from "../Hooks/useOnScreen";

const Game = () => {
  const imgRef = useRef();
  const isVisible = useOnScreen(imgRef);
  return (
    <div
      className={`flex flex-col-reverse lg:flex-row h-full w-full about bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 bg-gray-900  lg:bg-none`}
      ref={imgRef}
    >
      {/* <img
        src={gif}
        alt="gif"
        className={`${
          isVisible ? "aboutBGactive" : "aboutBG"
        } opacity-0 lg:opacity-100`}
      /> */}
      <div className="w-full lg:p-10 flex flex-col justify-center align-middle ">
        <div className=" py-12 px-6 lg:px-24 lg:bg-gray-100 rounded">
          <div className="lg:text-center">
            {/* <p className="text-lg leading-6 font-semibold text-indigo-600 uppercase tracking-wider">
              About the Game
            </p> */}
            <h2 className="mt-4 lg:text-4xl text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              Strings: Madness Within
            </h2>
            <p className="mt-4 max-w-2xl md:text-xl text-lg text-gray-700 lg:mx-auto">
              Welcome to Strings: Madness Within, an immersive and captivating
              platformer adventure set in the mysterious Gloomhaven Manor.
            </p>
          </div>
          <div className="mt-10">
            <div className="grid grid-cols-1 gap-10 lg:grid-cols-2 lg:gap-0">
              <div className="flex flex-col items-start">
                <h3 className="text-lg leading-6 font-semibold text-indigo-600 uppercase tracking-wider mb-2">
                  Key Features
                </h3>
                <ul className="grid grid-cols-1 gap-y-4">
                  <li className="flex items-start">
                    <span className="h-6 flex items-center sm:h-7">
                      <svg
                        className="flex-shrink-0 h-5 w-5 text-indigo-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="9"
                          stroke="currentColor"
                          strokeWidth="2"
                        ></circle>
                        <path d="M4 10v4l4-2-4-2z" fill="currentColor"></path>
                      </svg>
                    </span>
                    <p className="ml-2">Immersive Gameplay</p>
                  </li>

                  <li className="flex items-start">
                    <span className="h-6 flex items-center sm:h-7">
                      <svg
                        className="flex-shrink-0 h-5 w-5 text-indigo-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="9"
                          stroke="currentColor"
                          strokeWidth="2"
                        ></circle>
                        <path d="M4 10v4l4-2-4-2z" fill="currentColor"></path>
                      </svg>
                    </span>
                    <p className="ml-2">Intricate Puzzles</p>
                  </li>
                  <li className="flex items-start">
                    <span className="h-6 flex items-center sm:h-7">
                      <svg
                        className="flex-shrink-0 h-5 w-5 text-indigo-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="9"
                          stroke="currentColor"
                          strokeWidth="2"
                        ></circle>
                        <path d="M4 10v4l4-2-4-2z" fill="currentColor"></path>
                      </svg>
                    </span>
                    <p className="ml-2">Dynamic Platforming</p>
                  </li>
                  <li className="flex items-start">
                    <span className="h-6 flex items-center sm:h-7">
                      <svg
                        className="flex-shrink-0 h-5 w-5 text-indigo-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="9"
                          stroke="currentColor"
                          strokeWidth="2"
                        ></circle>
                        <path d="M4 10v4l4-2-4-2z" fill="currentColor"></path>
                      </svg>
                    </span>
                    <p className="ml-2">Engaging Narrative</p>
                  </li>
                  <li className="flex items-start">
                    <span className="h-6 flex items-center sm:h-7">
                      <svg
                        className="flex-shrink-0 h-5 w-5 text-indigo-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="9"
                          stroke="currentColor"
                          strokeWidth="2"
                        ></circle>
                        <path d="M4 10v4l4-2-4-2z" fill="currentColor"></path>
                      </svg>
                    </span>
                    <p className="ml-2">Beautiful Art Style</p>
                  </li>
                  <li className="flex items-start">
                    <span className="h-6 flex items-center sm:h-7">
                      <svg
                        className="flex-shrink-0 h-5 w-5 text-indigo-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="9"
                          stroke="currentColor"
                          strokeWidth="2"
                        ></circle>
                        <path d="M4 10v4l4-2-4-2z" fill="currentColor"></path>
                      </svg>
                    </span>
                    <p className="ml-2">Unlockable Secrets</p>
                  </li>
                  {/* Add more features here */}
                </ul>
              </div>
              <div className="flex flex-col items-start">
                <h3 className="text-lg leading-6 font-semibold text-indigo-600 uppercase tracking-wider mb-2">
                  About the Game
                </h3>
                <p className="md:text-lg text-sm text-gray-700">
                  Prepare to embark on an unforgettable journey filled with
                  mystery, danger, and intrigue in Strings: Madness Within. Are
                  you ready to uncover the secrets of Gloomhaven Manor and solve
                  the mystery of Evelyn's disappearance? Join Alan on his quest
                  and experience the thrill of discovery in this captivating
                  platformer adventure.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full p-10 flex flex-col justify-center align-middle">
        <img src={gameArt} alt="Game Art" className="rounded-lg" />
      </div>
    </div>
  );
};

export default Game;
