import React from "react";
import fb from "../Images/Socials/fb.png";
import x from "../Images/Socials/x.png";
import ig from "../Images/Socials/ig.png";
import rdt from "../Images/Socials/rdt.png";
import dscrd from "../Images/Socials/dscrd.png";
import tktk from "../Images/Socials/tktk.png";
import tlgrm from "../Images/Socials/tlgrm.png";
import whtsp from "../Images/Socials/whtsp.png";
import buyMeACoffee from "../Images/Socials/coffeeBtn.png";
import logo from "../Images/LogoTransparent.png";
import SubscriptionForm from "./SubscriptionForm";

const SocialLinks = () => {
  const socials = [
    {
      name: "X (Twitter)",
      icon: x,
      url: "https://twitter.com/ThornfruitGames",
    },
    {
      name: "Instagram",
      icon: ig,
      url: "https://www.instagram.com/thornfruitgames/",
    },
    {
      name: "Facebook",
      icon: fb,
      url: "https://www.facebook.com/profile.php?id=61556982932049",
    },
    {
      name: "Reddit",
      icon: rdt,
      url: "https://www.reddit.com/user/Thornfruit/",
    },
    {
      name: "Discord",
      icon: dscrd,
      url: "https://discord.gg/zH3CApnwsq",
    },
    // Add more socials as needed
  ];

  return (
    <section className="bg-gray-900 text-white py-12 border-y-2 p-3">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-evenly">
        <div className="mb-6 md:mb-0">
          <h2 className="text-2xl font-bold mb-4">Connect with us</h2>
          <div className="flex items-center space-x-4">
            {socials.map((social, index) => (
              <a
                href={social.url}
                key={index}
                target="_blank"
                rel="noopener noreferrer"
                className=" bg-white bg-opacity-25 p-2 rounded-full"
              >
                <img src={social.icon} alt={social.name} className="w-8 h-8" />
              </a>
            ))}
          </div>
          <p className="mt-4">
            Follow us for the latest updates and announcements!
          </p>
          <div className="mt-6">
            <a
              href="https://www.buymeacoffee.com/thornfruitgames"
              target="_blank"
              rel="noopener noreferrer"
              className=""
            >
              <img src={buyMeACoffee} alt="Buy Me A Coffee" className="w-56" />
            </a>
          </div>
        </div>
        {/* <img src={logo} alt="logo" className="w-60" /> */}
        {/* <SubscriptionForm /> */}
        <div className="w-1/2">
          {/* <iframe
            src="https://discord.com/widget?id=1113133884117373061&theme=dark"
            width="350"
            height="500"
            allowtransparency="true"
            frameborder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          ></iframe> */}
        </div>
      </div>
    </section>
  );
};

export default SocialLinks;
