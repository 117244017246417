import React, { useState } from "react";

const TermsOfServiceModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        onClick={openModal}
        className="text-xs lg:mr-4 mt-1 lg:m-0 cursor-pointer text-white"
      >
        Terms of Service
      </button>
      {isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            ></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    Terms of Service
                  </h3>
                  <p className="text-sm text-gray-500 mt-2">
                    Last Updated: 11 March 2024
                  </p>
                  <div className="mt-4">
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      Introduction
                    </h4>
                    <p className="text-sm text-gray-500">
                      Thank you for visiting Thornfruit Games. These Terms of
                      Service govern your use of our website. By accessing or
                      using our website, you agree to comply with these terms
                      and conditions.
                    </p>
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      User Conduct
                    </h4>
                    <p className="text-sm text-gray-500">
                      You agree not to engage in any activity that disrupts or
                      interferes with the operation of our website or its
                      services. This includes but is not limited to hacking,
                      cracking, or any other unauthorized access to our systems
                      or networks.
                    </p>
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      Content
                    </h4>
                    <p className="text-sm text-gray-500">
                      All content provided on our website is for informational
                      purposes only. We do not guarantee the accuracy or
                      completeness of any information provided, and we are not
                      liable for any errors or omissions.
                    </p>
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      Intellectual Property
                    </h4>
                    <p className="text-sm text-gray-500">
                      The content, logos, and trademarks displayed on our
                      website are the property of Thornfruit Games and are
                      protected by copyright and other intellectual property
                      laws. You may not use, reproduce, or distribute any
                      content from our website without our prior written
                      consent.
                    </p>
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      Disclaimer
                    </h4>
                    <p className="text-sm text-gray-500">
                      Our website is provided on an "as is" and "as available"
                      basis without any warranties of any kind, express or
                      implied. We do not guarantee that our website will be
                      uninterrupted, secure, or error-free.
                    </p>
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      Limitation of Liability
                    </h4>
                    <p className="text-sm text-gray-500">
                      In no event shall Thornfruit Games be liable for any
                      indirect, incidental, special, or consequential damages
                      arising out of or in any way connected with your use of
                      our website.
                    </p>
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      Governing Law
                    </h4>
                    <p className="text-sm text-gray-500">
                      These Terms of Service shall be governed by and construed
                      in accordance with the laws of Israel.
                    </p>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    onClick={closeModal}
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-700 text-base font-medium text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TermsOfServiceModal;
