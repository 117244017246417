import React, { useState } from "react";

const PrivacyPolicyModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        onClick={openModal}
        className="text-xs mb-1 lg:m-0 cursor-pointer text-white"
      >
        Privacy Policy
      </button>
      {isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            ></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 "
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900 "
                    id="modal-headline"
                  >
                    Privacy Policy
                  </h3>
                  <p className="text-sm text-gray-500 mt-2">
                    Last Updated: 11 March 2024
                  </p>
                  <div className="mt-4">
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      Introduction
                    </h4>
                    <p className="text-sm text-gray-500">
                      Thank you for visiting Thornfruit Games. This Privacy
                      Policy explains how we collect and use your personal
                      information when you subscribe to our newsletter.
                    </p>
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      Information Collected
                    </h4>
                    <p className="text-sm text-gray-500">
                      We collect your email address when you voluntarily
                      subscribe to our newsletter.
                    </p>
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      Use of Information
                    </h4>
                    <p className="text-sm text-gray-500">
                      We use the email addresses collected solely for the
                      purpose of sending our newsletter and related updates. We
                      do not share, sell, or disclose your email address to any
                      third parties.
                    </p>
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      Data Security
                    </h4>
                    <p className="text-sm text-gray-500">
                      We take reasonable measures to protect the security of
                      your email address and ensure it is not accessed,
                      disclosed, or used without authorization.
                    </p>
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      User Rights
                    </h4>
                    <p className="text-sm text-gray-500">
                      You have the right to unsubscribe from our newsletter at
                      any time. You can do so by clicking the "Unsubscribe" link
                      provided in our newsletter emails or by contacting us
                      directly at thornfruitgames@gmail.com.
                    </p>
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      Cookies and Tracking
                    </h4>
                    <p className="text-sm text-gray-500">
                      We do not use cookies or tracking technologies to collect
                      personal information from visitors to our website.
                    </p>
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      Third-Party Links
                    </h4>
                    <p className="text-sm text-gray-500">
                      Our website may contain links to third-party websites. We
                      are not responsible for the privacy practices or content
                      of those websites. We encourage you to review the privacy
                      policies of any third-party sites you visit.
                    </p>
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      Children's Privacy
                    </h4>
                    <p className="text-sm text-gray-500">
                      Our website is not directed at children under the age of
                      13. We do not knowingly collect personal information from
                      children under 13 years of age. If you are a parent or
                      guardian and believe that your child has provided us with
                      personal information, please contact us so that we can
                      delete the information.
                    </p>
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      Updates to the Privacy Policy
                    </h4>
                    <p className="text-sm text-gray-500">
                      We may update this Privacy Policy from time to time. Any
                      changes will be effective immediately upon posting the
                      revised policy on our website.
                    </p>
                    <h4 className="text-sm font-semibold mb-2 text-gray-900 mt-5">
                      Contact Information
                    </h4>
                    <p className="text-sm text-gray-500">
                      If you have any questions or concerns about our Privacy
                      Policy or the use of your personal information, please
                      contact us at thornfruitgames@gmail.com.
                    </p>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    onClick={closeModal}
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-700 text-base font-medium text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivacyPolicyModal;
