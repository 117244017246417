import React from "react";
import logo from "../Images/LogoTransparent.png";

const Header = () => {
  return (
    <div className="flex items-center justify-center p-3 text-center bg-gradient-to-r from-gray-900 via-gray-700 to-gray-900 shadow-xxl relative h-24">
      <img src={logo} alt="Logo" className="w-48 md:w-80 absolute" />
    </div>
  );
};

export default Header;
