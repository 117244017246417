import React from "react";

const ImageScroller = ({ images, isReverse }) => {
  return (
    <div className="flex flex-row  relative w-full overflow-hidden bg-gradient-to-r from-gray-900 via-black to-gray-900 border-y-2 border-gray-800">
      <div className="flex">
        <div
          className={isReverse ? "scroller_reverse" : "scroller"}
          style={{ "--speed": `${15000}ms` }}
        >
          {images.map((image, index) => (
            <img
              src={image}
              alt={`Showcase Image #${index}`}
              key={index}
              className="scrollingImg"
            />
          ))}
        </div>
        <div
          className={isReverse ? "scroller_reverse" : "scroller"}
          style={{ "--speed": `${15000}ms` }}
        >
          {images.map((image, index) => (
            <img
              src={image}
              alt={`Showcase Image #${index}`}
              key={index}
              className="scrollingImg"
            />
          ))}
        </div>
        <div
          className={isReverse ? "scroller_reverse" : "scroller"}
          style={{ "--speed": `${15000}ms` }}
        >
          {images.map((image, index) => (
            <img
              src={image}
              alt={`Showcase Image #${index}`}
              key={index}
              className="scrollingImg"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageScroller;
