import img1 from "../Images/Scroller/img (1).png";
import img2 from "../Images/Scroller/img (2).png";
import img3 from "../Images/Scroller/img (3).png";
import img4 from "../Images/Scroller/img (4).png";
import img5 from "../Images/Scroller/img (5).png";
import img6 from "../Images/Scroller/img (6).png";
import img7 from "../Images/Scroller/img (7).png";
import img8 from "../Images/Scroller/img (8).png";
import img9 from "../Images/Scroller/img (9).png";
import img10 from "../Images/Scroller/img (10).png";
import img11 from "../Images/Scroller/img (11).png";
import img12 from "../Images/Scroller/img (12).png";
import img13 from "../Images/Scroller/img (13).png";
import img14 from "../Images/Scroller/img (14).png";
import img15 from "../Images/Scroller/img (15).png";
import img16 from "../Images/Scroller/img (16).png";
import img17 from "../Images/Scroller/img (17).png";
import img18 from "../Images/Scroller/img (18).png";
import img19 from "../Images/Scroller/img (19).png";
import img20 from "../Images/Scroller/img (20).png";
import img21 from "../Images/Scroller/img (21).png";

export const part1 = [img1, img2, img3, img4, img5, img6, img7];
export const part2 = [img8, img9, img10, img11, img12, img13, img14];
export const part3 = [img15, img16, img17, img18, img19, img20, img21];
