import logo from "./logo.svg";
import "./App.css";
import Header from "./Components/Header.jsx";
import Game from "./Components/Game.jsx";
import About from "./Components/About.jsx";
import ImageScroller from "./Components/ImageScroller.jsx";
import { part1, part2, part3 } from "./Tools/ScrollerImporter.js";
import Footer from "./Components/Footer.jsx";
import SocialLinks from "./Components/Socials.jsx";

function App() {
  return (
    <div className="App">
      <Header />
      <ImageScroller images={part1} />
      <Game />
      {/* <ImageScroller images={part2} isReverse={true} /> */}
      <About />
      <ImageScroller images={part3} />
      <SocialLinks />
      <Footer />
    </div>
  );
}

export default App;
