import React, { useEffect, useRef, useState } from "react";
import logo from "../Images/Logo.png";
import useOnScreen from "../Hooks/useOnScreen.js";

const About = () => {
  return (
    // <div
    //   className={`flex flex-row h-full w-full justify-center align-middle about`}
    //   ref={imgRef}
    // >
    <div className="flex flex-col lg:flex-row h-full w-full justify-center align-middle bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200">
      <div className={`w-full p-10 flex justify-center items-center`}>
        <img
          src={logo}
          alt="Thornfruit Games Logo"
          className="w-60 h-60 rounded-full shadow-lg"
        />
      </div>
      <div className="w-full lg:p-10 p-6 flex flex-col justify-center items-center text-gray-800">
        {/* <h1 className="text-6xl mb-5 font-bold">About</h1> */}
        <h2 className="md:text-3xl text-xl font-bold underline mb-3">
          Welcome to Thornfruit Games!
        </h2>
        <p className="md:text-xl text-sm leading-relaxed">
          Thornfruit Games is a creative haven where imagination takes flight
          and gaming dreams come to life. We're passionate about crafting
          immersive experiences that transport players to fantastical worlds and
          ignite their imagination.
        </p>
        <p className="md:text-xl text-sm leading-relaxed mt-4">
          As a solo game developer, I'm dedicated to crafting unique and
          memorable games that leave a lasting impression. With a focus on
          creativity, innovation, and storytelling, Thornfruit Games strives to
          push the boundaries of interactive entertainment and deliver
          unforgettable gaming adventures.
        </p>
        <p className="md:text-xl text-sm leading-relaxed mt-4">
          From thrilling narratives to captivating gameplay mechanics, each of
          our games is a labor of love, meticulously crafted to provide players
          with hours of enjoyment and exploration. Join us on this journey of
          discovery and adventure as we continue to develop new and exciting
          games that inspire, entertain, and delight players of all ages.
        </p>
      </div>
    </div>
  );
};

export default About;
