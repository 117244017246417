import React from "react";
import PrivacyPolicyModal from "./PrivacyPolicy"; // Import the PrivacyPolicyModal component
import TermsOfServiceModal from "./TermsOfService";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto lg:px-4 px-3">
        <div className="flex justify-between items-center">
          <p className="text-xs">
            © 2024 Thornfruit Games. All rights reserved.
          </p>
          <div className="flex flex-col lg:flex-row ">
            <TermsOfServiceModal />
            <PrivacyPolicyModal />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
